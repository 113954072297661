@import "_variables.scss";
@import "_darkTheme.scss";
@import "_lightTheme.scss";

html,
body,
#root,
.full-height {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background-color: $offWhite;
}

.siteContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-right: 0;
}

.contentWithHeader {
  flex: 1;
  padding: 5px !important;
  padding-left: 65px !important;
  padding-bottom: 50px !important;
}

.footerDesktop {
  min-height: 45px;
  flex: none;
  z-index: 2;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding-left: 65px !important;
}

.headerDesktop {
  min-height: 65px !important;
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  padding-left: 65px !important;
}

.stickyLeftMenu {
  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.stickyDupButton {
  z-index: 100;
  position: fixed;
  top: 10px;
  right: 15px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

.stickyLeftMenuCollapsed {
  width: 60px;
  display: flex;
  flex-direction: column;
}

.leftMenuButtons {
  height: 48px !important;
  width: 48px !important;
  padding-left: 9px !important;
}
.notificationBellButton {
  height: 36px !important;
  width: 36px !important;
}
.messageCenterPopout {
  border: 0;
  max-width: 750px;
  max-height: 75vh;
}
.barsButton {
  height: 38px !important;
  width: 38px !important;
  padding-left: 12px !important;
}

.topLeftMenuSection {
  flex: 1;
}

.bottomLeftMenuSection {
  flex: none;
}

.desktopContainer {
  min-height: 93vh;
}

.bgRed {
  background-color: red;
}
.fullHeight {
  min-height: 90vh;
}

.filter,
.sort-handle {
  cursor: pointer;
}

.filterableScreen {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
}

.filterPagerHeader {
  /* min-height: 65px; */
  flex: none;
  padding: 0;
}
.filter-definition-caption {
  padding-top: 8px;
  display: inline-block;
}

.contentWithFilters {
  flex: 1;
  padding: 0;
}

.pagerFooter {
  min-height: 65px;
  flex: none;
}

.minWidth85 {
  min-width: 85px !important;
}

.minWidth105 {
  min-width: 105px !important;
}
.maxWidth105 {
  max-width: 105px !important;
}

.minWidth150 {
  min-width: 150px !important;
}

.mapSection {
  height: 32vh;
}

.mapView {
  height: 30vh;
}

#root
  > div
  > div.contentWithHeader
  > div
  > div
  > div
  > div.m-0.p-0.fullHeight.row
  > div
  > div
  > div.contentWithFilters
  > div.mapSection
  > div {
  // overflow: visible !important;
  overflow-x: hidden !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  overflow-y: hidden;
}

th {
  border-top: none !important;
}

.inputGroupPrependAddon {
  min-width: 142px !important;
}

.routeDetailsCol {
  min-width: 400px !important;
}

.minWidth750 {
  min-width: 750px !important;
}

.minWidth800 {
  min-width: 800px !important;
}

.minHeight700 {
  min-height: 700px !important;
}

.minHeight150 {
  min-height: 150px !important;
}

.minHeight225 {
  min-height: 225px !important;
}

.minHeight38 {
  min-height: 38px !important;
}

.react-time-picker__wrapper {
  border-radius: 0.25rem !important;
  border: 1px solid #ced4da;
}
.fontSize85 {
  font-size: 0.85em !important;
}

.width50Percent {
  width: 50% !important;
}
.cursorPointer {
  cursor: pointer;
}
input[type="checkbox"] {
  -ms-transform: scale(1.75); /* IE */
  -moz-transform: scale(1.75); /* FF */
  -webkit-transform: scale(1.75); /* Safari and Chrome */
  -o-transform: scale(1.75); /* Opera */
  transform: scale(1.75);
  padding: 10px;
  margin-left: 0.45em;
}

.showDanger {
  background-color: $dangerColor !important;
  color: white !important;
}

.showWarning {
  background-color: $warningColor !important;
}

.statCard {
  padding-top: 16px !important;
  padding-top: 12px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  > div.row {
    > .card-title {
      font-size: 1.65em;
      color: $primaryColor;
    }
    > .card-stat {
      font-size: 1.45em;
      color: $infoColor;
    }
  }
}

.link-panel {
  border: solid 2px $infoColor;
  background-color: white;
  color: black !important;
  font-size: 1.75em !important;
  &:hover {
    background-color: $offWhite !important;
  }
  .linkIcon {
    font-size: 1.8rem;
  }
}

.clickable-icon {
  cursor: pointer;
  margin-left: 4px;
}

.no-decoration {
  text-decoration: none !important;
}

.sidebarLogo {
  max-width: 125px !important;
}

.signOutButton {
  padding-left: 0px !important;
  padding-right: 7px !important;
  display: block;
}

.publicPagePadRight {
  padding-right: 65px !important;
}

.flipHorizontal {
  transform: scale(-1, 1);
}

.signInImage {
  max-width: 400px !important;
}
.nav-link {
  cursor: pointer;
}
.subscription {
  cursor: pointer;
}
.selectedSubscription {
  border-color: $dangerColor !important;
}

.danger {
  background-color: $dangerColor !important;
  color: white !important;
}

.primary {
  background-color: $primaryColor !important;
  color: white !important;
}

.info {
  background-color: $infoColor !important;
  color: white !important;
}

.warning {
  background-color: $warningColor !important;
  color: white !important;
}

.dark {
  background-color: $darkColor !important;
  color: white !important;
}

.text-warning-darker {
  color: $projectWarningDarker !important;
}

.text-warning {
  color: $warningColor !important;
}

.text-primary {
  color: $primaryColor !important;
}

.text-danger {
  color: $dangerColor !important;
}

.text-royal {
  color: $royalBlue !important;
}
.pricing-card {
  text-align: center;
}
.price {
  font-size: 24px;
}
.bold {
  font-weight: bolder;
}
#userCircle {
  color: $royalBlue !important;
  width: 200px;
  height: 200px;
  text-shadow: 1px 1px 1px;
  border-color: $royalBlue !important;
}
.center-text {
  text-align: center;
}
img.user-profile-pic {
  width: 250px;
}
.dim-text {
  font-size: smaller;
  color: gray;
}
.tagline {
  text-align: center;
  color: $primaryColor !important; //$royalBlue
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  border: none;
  cursor: pointer;
}

.dropdown-content:hover,
.dropdown-content:focus {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.dropdown-content {
  position: relative; // absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  display: block;
  padding: 12px 16px;
  cursor: pointer;
  color: $royalBlue !important;
}
.title {
  font-size: 1.3em;
  color: $primaryColor;
}
.information {
  font-size: 14px;
  font-style: italic;
  color: #737373;
}
.information-small {
  font-size: 12px;
  font-style: italic;
  color: #737373;
}

.title-normal {
  font-size: 18px;
  color: $primaryColor;
}
.ratingSizeSmall {
  height: 30px !important;
  width: 30px !important;
  padding-left: 3px !important;
}

.spinnerCenter {
  // position: absolute;
  // top: 40vh;
  // left: 45vw;
  // margin: auto;
  // size: 1em;
  position: relative;
  left: 92%;
  top: 45%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.img-display {
  //margin: auto;
  /*  background-color: aliceblue;
*/
  padding-block: 4px 7px;
  border-right: 3px solid grey;
}

//chat styles
.chat {
  background-color: #ffffff;
  border: darkgrey 1px solid;
  border-radius: 0.25rem;
  margin: 0 1rem 1rem 1rem;
  cursor: pointer;
}

.chatNew {
  color: #ff0000;
  font-weight: bold;
}

.chatMessagesContainer {
  background-color: #ffffff;
  border-radius: 0.25rem;
  padding: 1rem;
}

.chatBubble {
  border: #a9a9a9 1px solid;
  border-radius: 1rem;
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}

.chatBubbleSend {
  background-color: #d3d3d3;
  margin-right: 2rem;
  float: left;
}

.chatBubbleReceive {
  background-color: #87cefa;
  margin-left: 2rem;
  float: right;
}

.rbc-calendar {
  height: 75vh !important;
}

// .rbc-time-view {
//   .rbc-label {
//   display: none;
//   }
//   .rbc-allday-cell {
//   height: calc(75vh);
//   max-height: unset;
//   }
//   .rbc-time-content {
//   display: none;
//   }
//   }
